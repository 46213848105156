<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Vendor <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  :items="vendors"
                  item-text="name"
                  return-object
                  v-model="vendor"
                  @change="onChangeVendor"
                  :rules="[v => !!v || 'Vendor is required']"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Form Number <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="lastFormNumber"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Return Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.returnNumber"
                  :rules="[v => !!v || 'Return Number is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Return Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuStart"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.returnDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateStart"
                    :allowed-dates="getAllowedDates()"
                    @input="menuStart = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Return Form</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  :items="listInvoice"
                  item-text="invoiceNumber"
                  return-object
                  v-model="invoice"
                  @change="onChangeInvoice"
                  :rules="[v => !!v || 'Return Form is required']"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab>
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6" class="pb-0"> </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">
                        Item Detail <span class="red--text">*</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="items"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickRow"
                      >
                        <template v-slot:item.no="{ item }">
                          {{ items.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.unitPrice="{ item }">
                          {{ formatPrice(item.unitPrice) }}
                        </template>
                        <template v-slot:item.total="{ item }">
                          {{ formatPrice(item.total) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title>Total</v-card-title>
              <v-card-text class="text-right">
                <span>Rp. {{ formatPrice(form.totalAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title>PPN</v-card-title>
              <v-card-text class="text-right">
                <span>Rp. {{ formatPrice(form.taxAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1" class="py-0">
        <v-row class="py-0">
          <v-col cols="12" class="mb-4 py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="submit">
                  <v-icon large>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-product
      :dialog="dialog"
      :item="item"
      :index="index"
      @close="close"
      @save="addItem"
      @deleteItem="deleteItem"
    ></dialog-product>
  </v-form>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import DialogProduct from "@/components/DialogProduct";
import { fiscalMonthMixin } from "@/services/mixins/fiscalMonthMixin";

export default {
  mixins: [fiscalMonthMixin],
  components: {
    "dialog-product": DialogProduct,
  },
  data: () => ({
    defaultForm: {
      formNumber: "",
      returnNumber: "",
      returnDate: moment().format("yyyy-MM-DD"),
      vendorName: "",
      vendorId: null,
      address: null,
      totalAmount: 0,
      purchaseInvoiceId: null,
      type: "",
      taxAmount: 0,
      products: [],
      status: "Outstanding",
    },
    menuStart: false,
    dateStart: new Date().toISOString().substr(0, 10),
    form: {},
    vendor: null,
    invoice: null,
    items: [],
    item: {},
    dialog: false,
    index: -1,
    listInvoice: [],
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Product Detail",
        value: "productDetail",
        sortable: false,
        divider: true,
      },
      {
        text: "Qty",
        value: "qty",
        sortable: false,
        divider: true,
      },
      {
        text: "U/M",
        value: "unitMeasure",
        sortable: false,
        divider: true,
      },
      {
        text: "U/Price",
        value: "unitPrice",
        sortable: false,
        divider: true,
      },
      {
        text: "Disc %",
        value: "discount",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "total",
        sortable: false,
        divider: true,
      },
      {
        text: "PO#",
        value: "poNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "DO#",
        value: "doNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Project",
        value: "project",
        sortable: false,
        divider: true,
      },
      {
        text: "Dept",
        value: "department",
        sortable: false,
        divider: true,
      },
      {
        text: "Reff",
        value: "reference",
        sortable: false,
        divider: true,
      },
    ],
  }),

  computed: {
    total() {
      let total = 0;
      this.items.map(x => {
        let subtotal = x.qty * x.unitPrice;
        total = total + subtotal;
      });
      return total * (this.invoice.percentageInvoice / 100);
    },
    discount() {
      let discount = 0;
      this.items.map(x => {
        let subtotal = x.qty * x.unitPrice;
        let amount = subtotal * (x.discount / 100);
        discount = discount + amount;
      });
      return discount * (this.invoice.percentageInvoice / 100);
    },

    ...mapState("purchaseReturn", ["lastFormNumber"]),
    ...mapState("external", ["vendors"]),
  },

  methods: {
    submit() {
      if (this.$refs.form.validate() && this.items.length > 0) {
        this.form.formNumber = this.lastFormNumber;
        this.form.products = this.items;

        let totalDebit = 0;
        let totalCredit = 0;

        if (totalDebit - totalCredit === 0) {
          this.$store.dispatch("purchaseReturn/create", this.form);
          this.clear();
        } else {
          let different = totalDebit - totalCredit;
          if (different > 0) {
            this.$store.dispatch("toast", {
              type: "error",
              message: `Journal not balance with -${Math.abs(different)} on Debit`,
            });
          } else {
            this.$store.dispatch("toast", {
              type: "error",
              message: `Journal not balance with -${Math.abs(different)} on Dredit`,
            });
          }
        }
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Methods, Invoices, and Accounts is required",
        });
      }
    },
    onChangeVendor(val) {
      this.form.vendorId = val.id;
      this.form.vendorName = val.name;
      this.form.address = val.address;
      this.getListInvoice(val.id);
    },
    getListInvoice(vendorId) {
      this.$store
        .dispatch("purchaseReturn/getListInvoice", vendorId)
        .then(response => (this.listInvoice = response.data));
    },
    onChangeInvoice(val) {
      this.form.purchaseInvoiceId = val.id;
      this.form.type = val.type;
      this.items = val.products;
      this.form.totalAmount = val.totalAmount;
      this.form.taxAmount = val.taxAmount;
    },
    tax() {
      let taxAmount = (this.total - this.discount) * (this.invoice.percentagePpn / 100);
      this.form.taxAmount = taxAmount;
    },
    deleteItem(index) {
      if (index !== -1) {
        this.items.splice(index, 1);
        this.form.totalAmount = this.total - this.discount;
        this.tax();
        this.dialog = false;
      }
    },
    addItem(item) {
      this.items[this.index] = item;
    },
    onClickRow(item) {
      this.item = item;
      this.index = this.items.map(x => x).indexOf(item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.form.totalAmount = this.total - this.discount;
      this.tax();
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    clear() {
      this.vendor = null;
      this.invoice = null;
      this.items = [];
      this.form = Object.assign({}, this.defaultForm);
    },
  },

  mounted() {
    this.$store.dispatch("purchaseReturn/getLastFormNumber");
    this.$store.dispatch("external/getVendors");
    this.form = Object.assign({}, this.defaultForm);
  },
};
</script>

<style></style>
